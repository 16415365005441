<template>
  <b-container class="py-5 px-4 px-md-5 container_alerts container_monitors">
    <b-row>
      <b-col cols="12" class="mb-4">
        <h2 class="fw-bold">Add new alert</h2>
      </b-col>
    </b-row>
    <b-row align-h="center" class="h-100">
      <b-col cols="12" md="8" lg="6" xl="5" align-self="center">
        <form>
          <b-card no-body class="py-4 py-md-5 px-0 px-md-4">
            <b-container>
              <b-row>
                <b-col>
                  <!-- Name -->
                  <b-form-group label="Name" label-for="name" class="text-start mb-3">
                    <b-form-input id="name" type="text" placeholder="Name" v-model="form.name"></b-form-input>
                  </b-form-group>
                  <!-- Status -->
                  <b-form-group label="Status" label-for="status" class="text-start mb-3">
                    <b-form-select v-model="form.status" :options="status" id="status" class="form-select">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <!-- Button -->
                  <div class="d-flex">
                    <b-button variant="secondary" class="mt-lg-3 px-5 ms-auto">Save</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          name: String(),
          status: null,
        },
        status: [
          { value: 1, text: 'Active' },
          { value: 0, text: 'Inactive' }
        ],
        load: false,
      }
    },
  }
</script>
